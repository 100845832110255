<template>
  <div class="p-grid">
    <div class="p-col-12">
      <Card>
        <template #content>
          <div class="p-p-3">
            <DataTable
              filterDisplay="menu"
              responsiveLayout="scroll"
              v-model:filters="filters"
              :alwaysShowPaginator="false"
              :totalRecords="count"
              :lazy="true"
              :loading="loading"
              :paginator="true"
              :rows="10"
              :value="menus"
              @filter="$emit('filter', $event)"
              @page="$emit('page', $event)"
            >
              <template #header>
                <div class="p-d-flex p-jc-between p-ai-center p-flex-sm-row">
                  <h4 class="p-mb-0">Page Builder</h4>
                  <Button
                    v-if="can('create', 'menu')"
                    type="button"
                    icon="pi pi-plus"
                    label="New Page"
                    class="p-button-outlined p-mb-2"
                    @click="$emit('show-editor')"
                  />
                </div>
              </template>
              <template #empty>
                <p class="p-text-center">No records found</p>
              </template>
              <Column field="title" header="Name" :showFilterMatchModes="false">
                <template #filter="{ filterModel }">
                  <InputText
                    type="text"
                    v-model="filterModel.value"
                    class="p-column-filter"
                    placeholder="Search by name"
                /></template>
              </Column>
              <Column field="cost_center.cost_center" header="OS" :showFilterMatchModes="false">
                <template #filter="{ filterModel }">
                  <MultiSelect
                    class="p-column-filter"
                    v-model="filterModel.value"
                    optionLabel="cost_center"
                    optionValue="id"
                    placeholder="Search by OS"
                    :loading="useMenuLoading"
                    :maxSelectedLabels="1"
                    :options="costCenterFilterOptions"
                    :showToggleAll="false"
                    @before-show="handleFetchCostCenterFilter"
                  />
                </template>
              </Column>
              <Column field="created_by" header="Created by" :showFilterMatchModes="false">
                <template #filter="{ filterModel }">
                  <InputText
                    type="text"
                    v-model="filterModel.value"
                    class="p-column-filter"
                    placeholder="Search by creator"
                /></template>
              </Column>
              <Column field="created" dataType="date" header="Created date" :showFilterMatchModes="false">
                <template #body="{ data }">{{ data.created.split('T')[0] }}</template>
                <template #filter="{ filterModel }">
                  <Calendar
                    id="icon"
                    placeholder="Filter by date"
                    dateFormat="yy-mm-dd"
                    v-model="filterModel.value"
                    selectionMode="range"
                    :showIcon="true"
                  />
                </template>
              </Column>
              <Column header="Sub Pages">
                <template #body="{ data }">{{ data?.submenus?.length || 0 }}</template>
              </Column>
              <Column headerStyle="min-width: 7rem" bodyStyle="text-align: center; overflow: visible">
                <template #body="{ data }">
                  <Button class="p-button-text p-button-rounded" icon="pi pi-pencil" @click="$emit('edit', data)" />
                  <Button
                    class="p-button-text p-button-danger p-button-rounded"
                    icon="pi pi-trash"
                    @click="handleDelete($event, data)"
                  />
                </template>
              </Column>
            </DataTable>
          </div>
        </template>
      </Card>
    </div>
  </div>
</template>

<script>
import { ref, watch } from 'vue'

import { FilterMatchMode } from 'primevue/api'
import { useAbility } from '@casl/vue'
import { useConfirm } from 'primevue/useconfirm'
import { useToast } from 'primevue/usetoast'

import useMenu from '../../hooks/useMenu'
import { handleHttpError } from '../../utils/utilities'

export default {
  name: 'MenuList',
  emits: ['delete', 'edit', 'filter', 'page', 'show-editor'],
  props: {
    count: { type: Number, required: true },
    loading: { type: Boolean, default: true },
    menus: { type: Array, required: true }
  },
  setup(props, { emit }) {
    const toast = useToast()
    const { can } = useAbility()
    const confirm = useConfirm()
    const { error: useMenuError, fetchCostCenterOptions, loading: useMenuLoading } = useMenu()

    const costCenterFilterOptions = ref(null)

    watch(useMenuError, (value) => {
      handleHttpError(value, toast)
    })

    // Datatable filter
    const filters = ref({
      title: { value: null, matchMode: FilterMatchMode.CONTAINS, query: 'title' },
      created_by: { value: null, matchMode: FilterMatchMode.CONTAINS, query: 'created_by' },
      created: { value: null, matchMode: FilterMatchMode.BETWEEN, query: 'created' },
      'cost_center.cost_center': { value: null, matchMode: FilterMatchMode.IN, query: 'cc' }
    })

    const handleFetchCostCenterFilter = async () => {
      const resp = await fetchCostCenterOptions({ is_filter: true })
      if (!useMenuError.value) {
        costCenterFilterOptions.value = resp.data
      }
    }

    const handleDelete = (event, data) => {
      const costCenterId = data.cost_center?.id
      confirm.require({
        target: event.currentTarget,
        message: 'Do you want to delete this page and its subpage(s)?',
        icon: 'pi pi-info-circle',
        acceptClass: 'p-button-danger',
        accept: () => emit('delete', { id: data.id, costCenterId })
      })
    }

    return { can, costCenterFilterOptions, filters, handleFetchCostCenterFilter, handleDelete, useMenuLoading }
  }
}
</script>

<style scoped>
</style>
