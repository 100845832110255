<template>
  <ModalEditor
    title="Page Editor"
    :visible="true"
    :closeButton="true"
    :showConfirmClose="formUpdated"
    @close="$emit('close')"
  >
    <template #content>
      <MenuForm v-model:title="formData.title" v-model:content="formData.content" v-model:icon="formData.icon" />
    </template>
    <template #footer>
      <Button
        v-if="mode === 'create'"
        data-test="button-editor-create"
        label="Add"
        :disabled="v.$invalid"
        @click="$emit('add-submenu', { ...formData, _id: uuidv4() })"
      />
      <Button
        v-else
        data-test="button-editor-update"
        label="Update"
        :disabled="v.$invalid || !formUpdated"
        @click="$emit('update-submenu', { ...formData })"
      />
    </template>
  </ModalEditor>
</template>

<script setup>
import { computed, reactive } from 'vue'

import { useVuelidate } from '@vuelidate/core'
import { v4 as uuidv4 } from 'uuid'

import MenuForm from './MenuForm.vue'
import ModalEditor from '../ModalEditor.vue'
import { EDITOR_MODE, MENU_FORM_INITIAL_STATE } from '../../definitions'

defineEmits(['add-submenu', 'close', 'update-submenu'])
const props = defineProps({ initialData: { type: Object, required: false } })

// data
let mode = EDITOR_MODE.CREATE
const cacheData = {}
const formData = reactive({ ...MENU_FORM_INITIAL_STATE })

if (props.initialData) {
  mode = EDITOR_MODE.UPDATE
  Object.assign(formData, { ...props.initialData })
}
Object.assign(cacheData, formData)

// computed
const formUpdated = computed(() => Object.keys(cacheData).some((field) => cacheData[field] !== formData[field]))

// validation
const v = useVuelidate()
</script>
