<template>
  <SubmenuEditor
    v-if="showSubmenuEditor"
    title="Sub Page Editor"
    :initialData="submenuEditorInitialData"
    @add-submenu="handleAddSubmenu"
    @close="handleCloseSubmenuEditor"
    @update-submenu="handleUpdateSubmenu"
  />

  <ModalEditor
    title="Page Editor"
    :visible="!showSubmenuEditor"
    :closeButton="true"
    :showConfirmClose="formUpdated"
    @close="$emit('close')"
  >
    <template #content>
      <MenuForm v-model:title="formData.title" v-model:content="formData.content" v-model:icon="formData.icon" />
      <LabeledField
        class="p-col-12"
        data-test="cost-center"
        id="menu-cost-center"
        label="OS"
        :autoLayout="false"
        :showHelp="v.cost_center.$invalid && v.cost_center.$dirty"
        :errors="v.cost_center.$silentErrors"
      >
        <Dropdown
          placeholder="Select OS"
          optionLabel="cost_center"
          optionValue="id"
          v-model="formData.cost_center"
          :options="costCenterOptions"
          :loading="loading"
          @change="v.cost_center.$touch()"
        />
      </LabeledField>
      <ScrollPanel v-if="formData.submenus?.length" class="p-col-12 custompanel" style="width: 100%; max-height: 300px">
        <SubmenuList v-model="formData.submenus" item-key="_id">
          <template #item="{ element }">
            <SubmenuListItem
              :submenu="element"
              @edit-submenu="handleEditSubmenuPrep(element)"
              @delete-submenu="handleDeleteSubmenu(element._id)"
            />
          </template>
        </SubmenuList>
      </ScrollPanel>
    </template>
    <template #footer>
      <Button
        data-test="button-add-submenu"
        class="p-button-text p-button-secondary"
        label="Add Sub Page"
        :disabled="loading"
        @click="showSubmenuEditor = true"
      />
      <Button
        v-if="mode === EDITOR_MODE.CREATE"
        data-test="button-editor-save"
        label="Save"
        :disabled="v.$invalid"
        :loading="loading"
        @click="handleSaveMenu"
      />
      <Button
        v-else
        data-test="button-editor-update"
        label="Update"
        :disabled="v.$invalid || !formUpdated"
        :loading="loading"
        @click="handleSaveMenu"
      />
    </template>
  </ModalEditor>
</template>

<script setup>
import { computed, reactive, ref, watch } from 'vue'

import { cloneDeep, isEqual } from 'lodash-es'
import SubmenuList from 'vuedraggable'
import { useStore } from 'vuex'
import { useVuelidate } from '@vuelidate/core'
import { required } from '@vuelidate/validators'
import { useToast } from 'primevue/usetoast'

import MenuForm from './MenuForm.vue'
import ModalEditor from '../ModalEditor.vue'
import SubmenuListItem from './SubmenuListItem.vue'
import SubmenuEditor from './SubmenuEditor.vue'
import useMenu from '../../hooks/useMenu'
import { handleHttpError } from '../../utils/utilities'
import { types } from '../../store/mutation-types'
import { EDITOR_MODE, MENU_FORM_INITIAL_STATE } from '../../definitions'

const emit = defineEmits(['close', 'submitted'])
const props = defineProps({
  initialData: { type: Object, required: false }
})

// hooks
const store = useStore()
const toast = useToast()
const { createMenu, error: useMenuError, fetchCostCenterOptions, loading, updateMenu } = useMenu()

// data
let mode = EDITOR_MODE.CREATE
const submenus = ref([])
const formData = reactive({ ...MENU_FORM_INITIAL_STATE, submenus })
if (props.initialData) {
  mode = EDITOR_MODE.UPDATE
  Object.assign(formData, cloneDeep(props.initialData))
}
const cacheDate = { ...formData, submenus: cloneDeep(formData.submenus) } // remove cached submenu reactivity
const costCenterOptions = ref([])
const submenuEditorInitialData = ref(null)
const showSubmenuEditor = ref(false)

watch(useMenuError, (value) => {
  handleHttpError(value, toast)
})

const formUpdated = computed(() => !isEqual(formData, cacheDate))

// methods
const handleAddSubmenu = (menu) => {
  submenus.value.push(menu)
  showSubmenuEditor.value = false
}

const handleCloseSubmenuEditor = () => {
  submenuEditorInitialData.value = null
  showSubmenuEditor.value = false
}

const handleDeleteSubmenu = (_id) => (submenus.value = submenus.value.filter((el) => el._id !== _id))

const handleEditSubmenuPrep = (submenu) => {
  submenuEditorInitialData.value = cloneDeep(submenu)
  showSubmenuEditor.value = true
}

const handleUpdateSubmenu = (menu) => {
  const { title, content, icon } = menu
  const submenu = submenus.value.find((el) => el.id === menu.id)
  Object.assign(submenu, { title, content, icon })
  showSubmenuEditor.value = false
}

const handleSaveMenu = async () => {
  let resp
  const data = { ...formData, submenus: submenus.value.map((el, index) => ({ ...el, display_priority: index })) }
  if (mode === EDITOR_MODE.CREATE) {
    resp = await createMenu(data)
  } else {
    resp = await updateMenu(data)
  }
  if (!useMenuError.value) {
    const payload = { ...resp.data }
    const costCenterId = payload.cost_center
    delete payload.cost_center

    if (mode === EDITOR_MODE.CREATE) {
      store.commit(types.ADD_EMBED_MENU, { ...payload, costCenterId })
    } else {
      if (costCenterId === props.initialData.cost_center) {
        store.commit(types.UPDATE_EMBED_MENU, { ...payload, costCenterId })
      } else {
        store.commit(types.DELETE_EMBED_MENU, { ...payload, costCenterId: props.initialData.cost_center })
        store.commit(types.ADD_EMBED_MENU, { ...payload, costCenterId })
      }
    }
    emit('submitted', mode)
  }
}

// created
;(async () => {
  const resp = await fetchCostCenterOptions()
  if (!useMenuError.value && resp.data) {
    costCenterOptions.value = [...resp.data]
  }
})()

const rules = {
  cost_center: { required }
}

const v = useVuelidate(rules, formData)
</script>

<style lang="scss" scoped>
::v-deep(.p-scrollpanel) {
  &.custompanel {
    .p-scrollpanel-content {
      padding: 0 15 15px 0;
    }
  }
}
</style>
