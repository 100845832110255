<template>
  <LabeledField
    data-test="menu-title"
    class="p-col-12"
    id="menu-title"
    label="Page Name"
    :autoLayout="false"
    :showHelp="v.vtitle.$invalid && v.vtitle.$dirty"
    :errors="v.vtitle.$silentErrors"
  >
    <InputText
      data-test="form-title-input"
      placeholder="Enter page name"
      :value="title"
      @input="handleInput('update:title', $event.target.value, v.vtitle)"
    />
  </LabeledField>
  <LabeledField
    class="p-col-12"
    data-test="menu-content"
    id="menu-content"
    label="Page Content"
    :autoLayout="false"
    :showHelp="v.vcontent.$invalid && v.vcontent.$dirty"
    :errors="v.vcontent.$silentErrors"
  >
    <Textarea
      data-test="form-content-input"
      placeholder="Enter page content"
      :value="content"
      :rows="4"
      @input="handleInput('update:content', $event.target.value, v.vcontent)"
    />
  </LabeledField>
  <LabeledField class="p-col-12" data-test="menu-icon" id="menu-icon" label="Icon" :autoLayout="false">
    <Dropdown
      placeholder="Select an icon"
      :modelValue="icon"
      :options="PRIMEVUE_ICONS"
      :showClear="true"
      @change="handleInput('update:icon', $event.value, v.vicon)"
    >
      <template #option="slotProps">
        <div class="p-d-flex p-ai-center" style="gap: 0.8rem">
          <i :class="slotProps.option"></i>
          <div>{{ slotProps.option }}</div>
        </div>
      </template>
    </Dropdown>
  </LabeledField>
</template>

<script>
import { computed, toRefs } from 'vue'
import { useVuelidate } from '@vuelidate/core'
import { maxLength, required } from '@vuelidate/validators'
import { PRIMEVUE_ICONS } from '../../definitions'

export default {
  name: 'MenuForm',
  props: {
    title: { type: String, default: '', required: true },
    content: { type: String, default: '', required: true },
    icon: { type: String, default: '', required: false }
  },
  setup(props, { emit }) {
    const { title: vtitle, content: vcontent, icon: vicon } = toRefs(props)

    const handleInput = (eventName, event, vField) => {
      emit(eventName, event)
      vField.$touch()
    }

    const rules = computed(() => ({
      vtitle: { required, maxLength: maxLength(100) },
      vcontent: { required: false },
      vicon: { required: false }
    }))
    const v = useVuelidate(rules, { vtitle, vcontent, vicon })

    return { handleInput, PRIMEVUE_ICONS, v, vcontent, vicon, vtitle }
  }
}
</script>
