<template>
  <div class="p-component submenu-list-item p-mb-2">
    <div data-test="item" class="p-d-flex p-jc-between p-ai-center" @click.stop="$emit('edit-submenu')">
      <div class="submenu-item__detail">
        <div class="p-d-flex p-ai-center">{{ submenu.title }}</div>
      </div>
      <div class="submenu-item__action p-d-flex p-ai-center">
        <Button
          data-test="item-button"
          class="p-button-text p-button-rounded p-button-danger"
          icon="pi pi-times"
          @click.stop="handleDelete"
        />
      </div>
    </div>
  </div>
</template>

<script>
import confirmPopup from '../../hooks/useConfirmPopup'

export default {
  name: 'SubmenuListItem',
  emits: ['delete-submenu', 'edit-submenu'],
  props: {
    submenu: { type: Object, required: true }
  },
  setup(props, { emit }) {
    const { confirmDeleteItem } = confirmPopup()

    const handleDelete = (event, index) => {
      confirmDeleteItem(event, () => emit('delete-submenu'), null, `Do you want to delete ${props.submenu.title}?`)
    }

    return { handleDelete }
  }
}
</script>

<style scoped>
.submenu-list-item {
  box-sizing: border-box;
  border-radius: 5px;
  background-color: var(--surface-c);
  padding: 0.5rem 1rem;
}
.submenu-list-item:hover {
  cursor: pointer;
}
.submenu-list-item:active {
  cursor: pointer;
}
</style>
