<template>
  <MenuList
    :count="fetchData.count"
    :loading="loading"
    :menus="fetchData.results"
    @delete="handleMenuDelete"
    @edit="handleMenuEditPrep"
    @filter="handleMenuFilter"
    @page="handleMenuPage"
    @show-editor="showEditor = true"
  />
  <MenuEditor
    v-if="showEditor"
    :initialData="initialMenuEditorData"
    @close="handleCloseEditor"
    @submitted="handleMenuSubmitted"
  ></MenuEditor>
</template>

<script>
import { ref, watch } from 'vue'
import { useStore } from 'vuex'
import { useToast } from 'primevue/usetoast'

import MenuEditor from '../../components/menu/MenuEditor.vue'
import MenuList from '../../components/menu/MenuList.vue'
import useMenu from '../../hooks/useMenu'
import { types } from '../../store/mutation-types'
import { formatDateRangeFilter, handleHttpError, parseFilters } from '../../utils/utilities'

export default {
  name: 'MenuBuilder',
  components: { MenuEditor, MenuList },
  setup() {
    const store = useStore()
    const toast = useToast()
    const { deleteMenu, error: useMenuError, fetchData, fetchMenus, loading } = useMenu()

    // data
    const initialMenuEditorData = ref(null)
    const showEditor = ref(false)

    watch(useMenuError, (value) => {
      handleHttpError(value, toast)
    })

    // methods
    const handleCloseEditor = () => {
      initialMenuEditorData.value = null
      showEditor.value = false
    }

    const handleMenuDelete = async (menu) => {
      await deleteMenu(menu.id)
      if (useMenuError.value) return
      store.commit(types.DELETE_EMBED_MENU, menu)
      toast.add({ severity: 'success', summary: 'Success', detail: 'Page deleted', life: 3000 })
    }

    const handleMenuEditPrep = (data) => {
      initialMenuEditorData.value = {
        ...data,
        cost_center: data?.cost_center?.id,
        submenus: data.submenus
          .sort(({ display_priority: dpa }, { display_priority: dpb }) => dpa - dpb)
          .map((el) => ({ ...el, _id: el.id }))
      }
      showEditor.value = true
    }

    const handleMenuFilter = async (event) => {
      const filters = parseFilters(event.filters)
      const { created, cc } = filters
      if (created?.length) {
        ;[filters.created_after, filters.created_before] = formatDateRangeFilter(...created)
        delete filters.created
      }

      if (cc?.length) {
        filters.cost_centers = cc.join(',')
        delete filters.cc
      }

      const params = { ...filters }
      await fetchMenus(params)
    }

    const handleMenuPage = async (event) => {
      const { page } = event
      const filters = parseFilters(event.filters)
      await fetchMenus({ page: page + 1, ...filters })
    }

    const handleMenuSubmitted = async (mode) => {
      showEditor.value = false
      // fetchData.params -- use previous fetch parameters
      await fetchMenus(fetchData.params)
      if (useMenuError.value) return
      const toastDetail = `Page ${mode === 'create' ? 'added' : 'updated'}`
      toast.add({ severity: 'success', summary: 'Success', detail: toastDetail, life: 3000 })
      showEditor.value = false
    }

    ;(async () => {
      await fetchMenus()
    })()

    return {
      fetchData,
      handleCloseEditor,
      handleMenuDelete,
      handleMenuEditPrep,
      handleMenuFilter,
      handleMenuPage,
      handleMenuSubmitted,
      initialMenuEditorData,
      loading,
      showEditor
    }
  }
}
</script>

<style>
</style>
